import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import HeaderLogo from './HeaderLogo';
import UIStore from "../../../Stores/UIStore";

import './style/index.css';

function Header(props) {
  const { children, underLogo, upperLogo, logoSize } = props;

  let { headerRef } = props;

  return (
    <header ref={header => (UIStore.headerRef = header)} id="header">
      <Container className="header__container" fluid>
        <Row className="header-line" noGutters>
          <Col className="logo" xs={logoSize.xs} sm={logoSize.sm} lg={logoSize.lg}>
            <HeaderLogo>
              {upperLogo ? <div className="logo__upper">{upperLogo}</div> : null}
              <div className="logo__under">{underLogo}</div>
            </HeaderLogo>
          </Col>
          {children}
        </Row>
      </Container>
    </header>
  );
}

Header.defaultProps = {
  children: null,
  upperLogo: '',
  underLogo: '',
  headerRef: null,
  logoSize: {
    xs: '2',
    sm: '5',
    lg: '3',
    xl: ''
  }
};

export default Header;
